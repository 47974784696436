
    var riot = require('riot')
    
riot.tag2('cse-team-locator', '<form onsubmit="{submitform}" class="form-inline"> <div class="form-group"> <label>Enter your postcode </label> <input type="text" name="postcode" placeholder="postcode" value="{postcode}" oninput="{onupdate}" class="form-control"> <button type="submit" class="btn btn-success">Find</button> </div> </form> <div if="{!orgs.length &amp;&amp; !first_use}" class="alert alert-warning">None found based on your postcode, see the full list below</div> <table if="{orgs.length &gt; 0}" class="table table-bordered"> <tbody> <tr> <th>Area</th> <th>Email</th> <th>Telephone</th> </tr> <tr each="{org in orgs}"> <td>{org.name}</td> <td><a href="mailto:{org.email}">{org.email}</a></td> <td>{org.phone}</td> </tr> </tbody> </table>', 'cse-team-locator { margin-top: 10px; display: block; } cse-team-locator form { margin-bottom: 20px; }', '', function(opts) {
var cse_teams, parseUrl, resource;

resource = require('riot-kit/utils/apiResource.coffee');

cse_teams = new resource(opts.src || '/duocms/api/find_cse_team', true);

this.postcode = '';

this.first_use = true;

parseUrl = (function(_this) {
  return function(url) {
    var decode, match, pl, query, search, urlParams;
    pl = /\+/g;
    search = /([^&=]+)=?([^&]*)/g;
    decode = function(s) {
      return decodeURIComponent(s.replace(pl, " "));
    };
    query = window.location.search.substring(1);
    urlParams = {};
    while ((match = search.exec(query))) {
      urlParams[decode(match[1])] = decode(match[2]);
    }
    return urlParams;
  };
})(this);

this.on('mount', function() {
  var params;
  params = parseUrl(window.location.href);
  this.postcode = params.postcode;
  if (this.postcode != null) {
    return this.find_teams(this.postcode);
  }
});

this.onupdate = (function(_this) {
  return function(e) {
    return _this.postcode = e.target.value;
  };
})(this);

this.submitform = (function(_this) {
  return function() {
    return _this.find_teams(_this.postcode);
  };
})(this);

this.find_teams = (function(_this) {
  return function(postcode) {
    return cse_teams.get({
      postcode: postcode
    }, function(orgs) {
      _this.orgs = orgs;
      _this.first_use = false;
      return _this.update();
    });
  };
})(this);
});
    
  